<template>
    <div class="text-center">
        <cluji-card title="1 - Sélection des dates">
            <b-row>
                <b-col class="datetimepicker-container" lg="8" offset-lg="2">
                    <two-dates-picker @dateDebutChange="updateDateDebut" @dateFinChange="updateDateFin"/>
                </b-col>
            </b-row>
        </cluji-card>

        <height-fade-transition>
            <cluji-card title="2 - Sélection des objets souhaités" v-if="form.dateDebut && form.dateFin">
                <b-row>
                    <b-col class="text-center" lg="8" offset-lg="2" md="10" offset-md="1">
                        <div class="accordion" role="tablist">
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-1>Jeux de plateau</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-1" visible accordion="emprunt-accordion" role="tabpanel">
                                    <b-card-body>
                                        <select-empruntable-table v-model="form.jeux" :available="jeuxAvailable" type="jeux"
                                                                  ref="jeuxSelector"/>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-2>Jeux de rôle</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-2" accordion="emprunt-accordion" role="tabpanel">
                                    <b-card-body>
                                        <select-empruntable-table v-model="form.jdrs" :available="jdrsAvailable" type="jdrs"
                                                                  ref="jdrsSelector"/>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-3>Livres</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-3" accordion="emprunt-accordion" role="tabpanel">
                                    <b-card-body>
                                        <select-empruntable-table v-model="form.livres" :available="livresAvailable" type="livres"
                                                                  ref="livresSelector"/>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </cluji-card>
        </height-fade-transition>

        <height-fade-transition>
            <cluji-card v-if="form.dateDebut && form.dateFin && (form.jdrs.length || form.jeux.length || form.livres.length)">
                <template #title>
                    <span v-b-tooltip.hover="`N'hésitez pas à ajouter toute précision supplémentaire utile à votre demande`">
                        Commentaire &middot;
                        <small>
                            <font-awesome-icon icon="question-circle"/>
                        </small>
                    </span>
                </template>
                <b-row>
                    <b-col xl="8" offset-xl="2" lg="10" offset-lg="1" cols="12">
                        <balloon-editor v-model="form.commentaireUtilisateur"/>
                    </b-col>
                </b-row>
            </cluji-card>
        </height-fade-transition>

        <height-fade-transition>
            <cluji-card v-if="form.dateDebut && form.dateFin && (form.jdrs.length || form.jeux.length || form.livres.length)">
                <template #title>
                    <span v-b-tooltip.hover="'Vérifiez bien deux fois avant de valider'">
                        Résumé &middot;
                        <small>
                            <font-awesome-icon icon="question-circle"/>
                        </small>
                    </span>
                </template>
                <p>Emprunt {{ lcFirst(format2Dates(form.dateDebut, form.dateFin)) }}</p>
                <b-row>
                    <b-col xl="8" offset-xl="2" class="mb-2" v-if="cautionTotale > 0">
                        <div class="thin-border border-warning">
                            <p class="text-warning mt-2 mb-2">
                                <font-awesome-icon icon="exclamation-triangle" class="mr-2"/>
                                La caution pour cet emprunt s'élève à <strong>{{ cautionTotale }} €</strong>,
                                ne l'oubliez pas le moment venu.
                                <font-awesome-icon icon="exclamation-triangle" class="ml-2"/>
                            </p>
                        </div>
                    </b-col>
                    <b-col xl="4" lg="6" class="mt-1">
                        <div class="thin-border">
                            <h3 class="text-center m-2">Jeux de rôle</h3>
                            <hr class="m-3"/>
                            <ul class="text-left">
                                <li v-for="jdr in form.jdrs">{{ jdr.nom }}</li>
                            </ul>
                        </div>
                    </b-col>
                    <b-col xl="4" lg="6" class="mt-1">
                        <div class="thin-border">
                            <h3 class="text-center m-2">Jeux de plateau</h3>
                            <hr class="m-3"/>
                            <ul class="text-left">
                                <li v-for="jeu in form.jeux">{{ jeu.nom }}</li>
                            </ul>
                        </div>
                    </b-col>
                    <b-col offset-xl="0" xl="4" offset-lg="3" lg="6" class="mt-1">
                        <div class="thin-border">
                            <h3 class="text-center m-2">Livres</h3>
                            <hr class="m-3"/>
                            <ul class="text-left">
                                <li v-for="livre in form.livres">{{ livre.nom }}</li>
                            </ul>
                        </div>
                    </b-col>

                    <b-col cols="12">
                        <h3 class="mt-4">Commentaire :</h3>

                        <b-row>
                            <b-col xl="8" offset-xl="2" lg="10" offset-lg="1" cols="12" class="text-left">
                                <div class="ck-content" v-html="sanitize(form.commentaireUtilisateur)"></div>
                            </b-col>
                        </b-row>

                        <b-button variant="success" class="mt-3" @click="submit" :disabled="loading">
                            <font-awesome-icon icon="check"/>
                            Valider
                            <font-awesome-icon icon="sync" spin v-if="loading"/>
                        </b-button>
                    </b-col>
                </b-row>
            </cluji-card>
        </height-fade-transition>
    </div>
</template>

<script>
    const TwoDatesPicker         = () => import('@/components/TwoDatesPicker');
    const HeightFadeTransition   = () => import('@/components/HeightFadeTransition');
    const ClujiCard              = () => import('@/components/ClujiCard');
    const SelectEmpruntableTable = () => import('@/components/SelectEmpruntableTable');
    const BalloonEditor          = () => import('@/components/BalloonEditor');

    import {apiPath}            from '@/util/http';
    import {lcFirst}            from '@/util/text';
    import {format2Dates}       from '@/util/date';
    import alert                from '@/util/alert';
    import {updateBadgesBureau} from '@/util/badge';
    import {cautionTotale}      from '@/util/emprunt';

    import {sanitize} from 'dompurify';

    export default {
        name: "NouvelEmprunt",
        components: {
            TwoDatesPicker, HeightFadeTransition, ClujiCard, SelectEmpruntableTable, BalloonEditor
        },
        data: () => ({
            loading: false,
            form: {
                dateDebut: null,
                dateFin: null,
                jdrs: [],
                jeux: [],
                livres: [],
                commentaireUtilisateur: null
            },
            jdrsAvailable: [],
            jeuxAvailable: [],
            livresAvailable: []
        }),
        computed: {
            cautionTotale() {
                return cautionTotale(this.form);
            }
        },
        methods: {
            format2Dates,
            lcFirst,
            sanitize,
            updateDateDebut(val) {
                this.form.dateDebut = val;
            },
            updateDateFin(val) {
                this.form.dateFin = val;
            },
            onDatesUpdate() {
                if (this.form.dateDebut && this.form.dateFin && this.form.dateFin < this.form.dateDebut) {
                    this.form.dateFin = this.form.dateDebut;
                }

                if (this.form.dateDebut && this.form.dateFin) {
                    this.axios
                        .get(apiPath('availability_jeux_cluji', {
                            debut: this.form.dateDebut,
                            fin: this.form.dateFin
                        }))
                        .then(response => {
                            const selector = this.$refs.jeuxSelector;
                            selector.setSelectables(response.data);

                            // Preselect logic (if user arrived here from the detail modal of a borrowable)
                            if (this.$route.query.jeu && !this.form.jeux.length) {
                                //                       ^^^^^^^^^^^^^^^^^^^^^^
                                // Don't do anything if somehow the user has already started selecting things

                                // Find the item we want to pre-select
                                const preselect = this.jeuxAvailable.find(({id}) => id === parseInt(this.$route.query.jeu));

                                // Only toggle its state if we found it & it is selectable & it is not already selected
                                if (preselect && preselect._selectable && !selector.isItemSelected(preselect))
                                    selector.toggleSelection(preselect);
                            }
                        })
                        .catch(() => this.$toaster.error('Impossible de récupérer les disponibilités des jeux'))
                        .then(() => this.axios.get(apiPath('availability_livres_cluji', {
                            debut: this.form.dateDebut,
                            fin: this.form.dateFin
                        })))
                        .then(response => {
                            const selector = this.$refs.livresSelector;
                            selector.setSelectables(response.data);
                            if (this.$route.query.livre && !this.form.livres.length) {
                                const preselect = this.livresAvailable.find(({id}) => id === parseInt(this.$route.query.livre));
                                if (preselect && preselect._selectable && !selector.isItemSelected(preselect))
                                    selector.toggleSelection(preselect);
                            }
                        })
                        .catch(() => this.$toaster.error('Impossible de récupérer les disponibilités des livres'))
                        .then(() => this.axios.get(apiPath('availability_jdrs_cluji', {
                            debut: this.form.dateDebut,
                            fin: this.form.dateFin
                        })))
                        .then(response => {
                            const selector = this.$refs.jdrsSelector;
                            selector.setSelectables(response.data);
                            if (this.$route.query.jdr && !this.form.jdrs.length) {
                                const preselect = this.jdrsAvailable.find(({id}) => id === parseInt(this.$route.query.jdr));
                                if (preselect && preselect._selectable && !selector.isItemSelected(preselect))
                                    selector.toggleSelection(preselect);
                            }
                        })
                        .catch(() => this.$toaster.error('Impossible de récupérer les disponibilités des jeux de rôle'));
                }
            },
            loadAvailable() {
                alert.loading();
                this.axios.get(apiPath('list_jeux_cluji_description'))
                    .then(response => this.jeuxAvailable = response.data)
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux de plateau'))
                    .then(() => this.axios.get(apiPath('list_livres_cluji_description')))
                    .then(response => this.livresAvailable = response.data)
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des livres'))
                    .then(() => this.axios.get(apiPath('list_jdrs_cluji_description')))
                    .then(response => this.jdrsAvailable = response.data)
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux de rôle'))
                    .finally(alert.stopLoading);
            },
            submit() {
                if (this.loading) return; // Do not submit twice

                this.loading = true;
                this.axios.post(apiPath('new_emprunt'), this.form)
                    .then(() => {
                        this.$toaster.success('Emprunt enregistré avec succès, vous recevrez un mail quand le bureau aura examiné votre demande (pensez à vérifier vos spams)');
                        updateBadgesBureau();
                        this.$router.push({name: 'dashboard_emprunts'});
                    })
                    .catch(() => this.$toaster.error("Impossible d'enregistrer votre emprunt"))
                    .finally(() => this.loading = false);
            }
        },
        watch: {
            "form.dateDebut"(val) {
                this.form.dateDebut = val;
                this.onDatesUpdate();
            },
            "form.dateFin"(val) {
                this.form.dateFin = val;
                this.onDatesUpdate();
            }
        },
        mounted() {
            this.loadAvailable();
        }
    }
</script>

<style scoped>
    .thin-border {
        padding: 10px;
        border-radius: .375rem;
        border: 1px solid #cad1d7 !important;
    }

    .border-warning {
        border-color: #fb9043 !important;
        background-color: #fb904319;
    }
</style>